import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Meta } from "../../Components/Helpers/Meta";
import { Navbar } from "../../Components/Navbar/Navbar";
import "./Values.styles.css";

const Values = () => {
  const { t } = useTranslation();
  const language = i18next.language;
  const { lang } = useParams();

  //   useEffect(() => {
  //     if (lang) {
  //         i18next.changeLanguage(lang);
  //     }
  //     const updateTitle = () => {
  //         document.title = `${t("values-title")} - Cevher`;
  //       };

  //       updateTitle();

  //       const handleLanguageChanged = () => {
  //         updateTitle();
  //       };

  //       i18next.on("languageChanged", handleLanguageChanged);

  //       return () => {
  //         i18next.off("languageChanged", handleLanguageChanged);
  //       };
  //     }, [lang]);

  const openAccordion = (id) => {
    const element = document.getElementById(`${id}-trigger`);
    element.classList.toggle("is-active");
    document.getElementById(`${id}-icon`).classList.toggle("is-active");
  };

  return (
    <div>
      <Meta
        title={t("values-title")}
        description={t("corporate-values-ourvalues-desc")}
      />
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image c-hero-image-focus-top"
                alt=""
                src={require("../../assets/hero/values-and-ethics.15e0bacc.jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <div
                className="c-caption-section"
                style={{ color: "#fff", textAlign: "center" }}
              >
                {t("corporate")}
              </div>
              <h1 className="c-caption-header">
                {t("corporate-values-ourvaluesethicalprinciples")}
              </h1>
            </div>
          </div>
        </div>
        <div className="c-container">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                minHeight: 1,
                paddingRight: "1rem",
                paddingLeft: "1rem",
              }}
            >
              <div className="c-values-body">
                <h2>{t("corporate-values-ourvision")}</h2>
                <p>{t("corporate-values-ourvision-desc")}</p>
                <h2>{t("corporate-values-ourmission")}</h2>
                <p>{t("corporate-values-ourmission-desc")}</p>
                <h2>{t("corporate-values-ourvalues")}</h2>
                <p>{t("corporate-values-ourvalues-desc")}</p>
                <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                  <div className="c-accordion c-accordion-container ">
                    <div className="c-accordion-item">
                      <div
                        id="first-trigger"
                        className="c-accordion-trigger"
                        onClick={() => openAccordion("first")}
                      >
                        <div className="c-accordion-title">
                          {t("corporate-values-customer-focus")}
                        </div>
                        <div className="c-accordion-icon" id="first-icon">
                          <div className="c-accordion-icon-line" />
                          <div className="c-accordion-icon-line" />
                        </div>
                      </div>
                      <div className="c-accordion-details">
                        <div
                          className="c-accordion-content"
                          style={{ padding: "1.5rem" }}
                        >
                          {t("corporate-values-customer-focus-desc")}
                        </div>
                      </div>
                    </div>
                    <div className="c-accordion-item">
                      <div
                        id="second-trigger"
                        className="c-accordion-trigger"
                        onClick={() => openAccordion("second")}
                      >
                        <div className="c-accordion-title">
                          {t("corporate-values-trust")}
                        </div>
                        <div className="c-accordion-icon" id="second-icon">
                          <div className="c-accordion-icon-line" />
                          <div className="c-accordion-icon-line" />
                        </div>
                      </div>
                      <div className="c-accordion-details">
                        <div
                          className="c-accordion-content"
                          style={{ padding: "1.5rem" }}
                        >
                          {t("corporate-values-trust-desc")}
                        </div>
                      </div>
                    </div>
                    <div className="c-accordion-item">
                      <div
                        id="third-trigger"
                        className="c-accordion-trigger"
                        onClick={() => openAccordion("third")}
                      >
                        <div className="c-accordion-title">
                          {t("corporate-values-teamwork")}
                        </div>
                        <div className="c-accordion-icon" id="third-icon">
                          <div className="c-accordion-icon-line" />
                          <div className="c-accordion-icon-line" />
                        </div>
                      </div>
                      <div className="c-accordion-details">
                        <div
                          className="c-accordion-content"
                          style={{ padding: "1.5rem" }}
                        >
                          {t("corporate-values-teamwork-desc")}
                        </div>
                      </div>
                    </div>
                    <div className="c-accordion-item">
                      <div
                        id="fourth-trigger"
                        className="c-accordion-trigger"
                        onClick={() => openAccordion("fourth")}
                      >
                        <div className="c-accordion-title">
                          {t("corporate-values-onestep")}
                        </div>
                        <div className="c-accordion-icon" id="fourth-icon">
                          <div className="c-accordion-icon-line" />
                          <div className="c-accordion-icon-line" />
                        </div>
                      </div>
                      <div className="c-accordion-details">
                        <div
                          className="c-accordion-content"
                          style={{ padding: "1.5rem" }}
                        >
                          {t("corporate-values-onestep-desc")}
                        </div>
                      </div>
                    </div>
                    <div className="c-accordion-item">
                      <div
                        id="fifth-trigger"
                        className="c-accordion-trigger"
                        onClick={() => openAccordion("fifth")}
                      >
                        <div className="c-accordion-title">
                          {t("corporate-values-passion")}
                        </div>
                        <div className="c-accordion-icon" id="fifth-icon">
                          <div className="c-accordion-icon-line" />
                          <div className="c-accordion-icon-line" />
                        </div>
                      </div>
                      <div className="c-accordion-details">
                        <div
                          className="c-accordion-content"
                          style={{ padding: "1.5rem" }}
                        >
                          {t("corporate-values-passion-desc")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                  <img
                    src={require("../../assets/content/values-and-ethics.931f1232.jpg")}
                    alt=""
                    className="c-values-img"
                  />
                </div>
                <div style={{ marginTop: "2.5rem", marginBottom: "1.5rem" }}>
                  <h2>{t("corporate-values-ourethical")}</h2>
                  <p>{t("corporate-values-ourethical-desc")}</p>
                </div>
                <a
                  href={
                    language === "en"
                      ? "https://www.cevher.com/static/docs/code-of-conduct-en.pdf"
                      : "https://www.cevher.com/static/docs/code-of-conduct-tr.pdf"
                  }
                  className="c-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("corporate-values-ourethical-btn")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Values;
